import apiClient from '@/common/apiClient';

type Credentials = {
  email: string;
  password: string;
  name?: string;
};

export default {
  login(credentials: Credentials) {
    return apiClient.post('/auth/login', credentials);
  },
  register(credentials: Credentials) {
    return apiClient.post('/auth/register', credentials);
  },
  addUser(user: Credentials): void {
    localStorage.setItem('user', JSON.stringify(user));
  },
  removeUser(): void {
    localStorage.removeItem('user');
  },
};
