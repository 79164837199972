import { createStore } from 'vuex';
import LeadsService from '@/services/LeadsService';
import AuthService from '@/services/AuthService';
import TokenService from '@/services/TokenService';

export default createStore({
  state: {
    user: null,
    logs: [],
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData.user;
      AuthService.addUser(userData.user);
      TokenService.setToken(userData.token);
    },
    CLEAR_USER_DATA(state) {
      state.user = null;
      AuthService.removeUser();
      TokenService.clearToken();
    },
    SET_LOG_DATA(state, logData) {
      state.logs = logData;
    },
  },
  actions: {
    register({ commit }, credentials) {
      return AuthService.register({ ...credentials })
        .then((res) => {
          commit('SET_USER_DATA', res.data);
        })
        .catch((e) => console.log(e));
    },
    login({ commit }, credentials) {
      return AuthService.login({ ...credentials })
        .then((res) => {
          commit('SET_USER_DATA', res.data);
        })
        .catch((e) => console.log(e));
    },
    logout({ commit }) {
      commit('CLEAR_USER_DATA');
    },
    getLogs({ commit }) {
      return LeadsService.getAllLeads()
        .then((res) => {
          const payload = res.data;
          if (payload.success) {
            const sortedLogs = payload.data.sort(
              (a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
            );
            commit('SET_LOG_DATA', sortedLogs);
          }
        })
        .catch((e) => console.log(`This is the leads error: ${e}`));
    },
  },
  modules: {},
});
