import apiClient from '@/common/apiClient';

const storageKey = 'token';

type Token = string;

const setToken = (token: Token): void => {
  localStorage.setItem(storageKey, token);
};

// Add interceptor to handle 401 response and send refresh token
apiClient.interceptors.response.use((response) => response, (error) => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // error on login
  if (error.response.config.url === '/auth/login') {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
  // error on refresh
  if (error.response.config.url === '/auth/refresh') {
    // Login user out
    console.log("User should've been logged out");
    console.log('Token rejected');
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  return apiClient.post('/auth/refresh').then(
    (success) => {
      const { config } = error.response;
      const { token } = success.data;
      setToken(token);
      return apiClient(config);
    },
  );
});

export default {
  setToken,
  getToken(): Token | null {
    return localStorage.getItem(storageKey);
  },
  clearToken(): void {
    localStorage.removeItem(storageKey);
    apiClient.defaults.headers.common.Authorization = '';
  },
  refreshToken() {
    return apiClient.post('/auth/refresh');
  },
  verify(token: Token) {
    return apiClient.get(`/auth?token=${token}`);
  },
};
