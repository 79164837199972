import axios from 'axios';

const baseURL = 'https://api.telidesk.com/v1';
// const baseURL = 'http://localhost:3030/v1';
const instance = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const newConfig = config;
    if (token && newConfig && newConfig.headers) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  },
  (error) => Promise.reject(error),
);

instance.defaults.withCredentials = true;

export default instance;
